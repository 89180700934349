// libraries
import { useHistory } from "react-router-dom"
import parse from 'html-react-parser'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

// components & functions
import Breadcrumb from "../../components/Breadcrumb"

// assets
import arrow_right from "../../../assets/img/utils/arrow_right.svg"
import img23 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi23.png'
import img22 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi22.png'
import img21 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi21.png'
import img20 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi20.png'
import img19 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi19.png'
import img18 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi18.png'
import img17 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi17.png'
import img16 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi16.png'
import img15 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi15.png'
import img14 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi14.png'
import img13 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi13.png'
import img12 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi12.png'
import img11 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi11.png'
import img10 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi10.png'
import img9 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi9.png'
import img8 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi8.png'
import img7 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi7.png'
import img6 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi6.png'
import img5 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi5.png'
import img4 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi4.png'
import img3 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi3.png'
import img2 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi2.png'
import img1 from '../../../assets/img/hesabatlar/menzil-sechimi-stats/menzil_sechimi1.png'

const MenzilSechimiStatPage = () => {
    const history = useHistory()
    const { statId } = useParams()
    const [stat, setStat] = useState()
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:agentlik'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/hesabatlar/satis',
            title: t('agentlik:audits_sales'),
            isActive: true
        }
    ]

    const audit_card = [
        {
            id: '23',
            text: "Yasamal Yaşayış Kompleksinin ikinci mərhələsində, Hövsan Yaşayış Kompleksinin ikinci mərhələsində və Gəncə Yaşayış Kompleksində seçilmiş mənzillərlə bağlı statistik məlumat <br/> 29.07.2023",
            img: img23
        },
        {
            id: 1,
            text: "Yasamal Yaşayış Kompleksinin ikinci mərhələsində <br/> seçilmiş mənzillərlə bağlı statistik məlumat<br/> 24.09.2022",
            img: img1
        },
        {
            id: 2,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 17.08.2021",
            img: img2
        },
        {
            id: 3,
            text: "Hövsan Yaşayış Kompleksində <br/> seçilmiş mənzillərlə bağlı statistik məlumat <br/> 01.07.2021",
            img: img3
        },
        {
            id: 4,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 01.07.2021",
            img: img4
        },
        {
            id: 5, // 18
            text: "Hövsan Yaşayış Kompleksində <br/> seçilmiş mənzillərlə bağlı statistik məlumat <br/> 18.12.2020",
            img: img5
        },
        {
            id: 6,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar 18.12.2020",
            img: img6
        },
        {
            id: 7,
            text: "Hövsan Yaşayış Kompleksində <br/> seçilmiş mənzillərlə bağlı statistik məlumat <br/> 10.09.2020",
            img: img7
        },
        {
            id: 8,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 10.09.2020",
            img: img8
        },
        {
            id: 9, // 14
            text: "Hövsan Yaşayış Kompleksində <br/> seçilmiş mənzillərlə bağlı statistik məlumat <br/> 30.06.2020",
            img: img9
        },
        {
            id: 10,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 30.06.2020",
            img: img10
        },
        {
            id: 11,
            text: "Yasamal Yaşayış Kompleksində satışa çıxarılmış binalarda seçilmiş mənzillərlə bağlı statistik məlumat 25.12.2019",
            img: img11
        },
        {
            id: 12,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 25.12.2019",
            img: img12
        },
        {
            id: 13, // 10
            text: "Yasamal Yaşayış Kompleksində satışa çıxarılmış binalarda <br/> seçilmiş mənzillərlə bağlı statistik məlumat <br/> 16.10.2019",
            img: img13
        },
        {
            id: 14,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 16.10.2019",
            img: img14
        },
        {
            id: 15,
            text: "Yasamal Yaşayış Kompleksində satışa çıxarılmış 6-13 saylı binalarda<br/> seçilmiş mənzillərlə bağlı statistik məlumat<br/> 12.07.2019",
            img: img15
        },
        {
            id: 16,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 12.07.2019",
            img: img16
        },
        {
            id: 17,
            text: "Yasamal Yaşayış Kompleksində satışa çıxarılmış 6, 7, 8 və 9 saylı binalarda<br/> seçilmiş mənzillərlə bağlı statistik məlumat<br/> 29.04.2019",
            img: img17
        },
        {
            id: 18,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 29.04.2019",
            img: img18
        },
        {
            id: 19,
            text: "Yasamal Yaşayış Kompleksində satışa çıxarılmış 1, 2, 3, 4, 5, 6 və 7 saylı binalarda<br/> seçilmiş mənzillərlə bağlı statistik məlumat<br/> 25.01.2019",
            img: img19
        },
        {
            id: 20,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 25.01.2019",
            img: img20
        },
        {
            id: 21,
            text: "Yasamal Yaşayış Kompleksində satışa çıxarılmış 1, 2, 3, 4 və 5 saylı binalarda <br/> seçilmiş mənzillərlə bağlı statistik məlumat <br/> 05.09.2018",
            img: img21
        },
        {
            id: 22,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 05.09.2018",
            img: img22
        }
    ]

    useEffect(() => {
        setStat(audit_card.find(x => x.id == statId))
    }, [statId])

    return (
        <div className="audit-page block-bg1 pt-md-3 pt-5">
            <Breadcrumb data={breadcrumb} className="mt-4 mb-0" style={{ height: 0 }} />
            {stat &&
                <>
                    <div style={{ background: "#223A5E" }} className="mt-5 mb-3 t-color4 text-center">
                        <h2 className="fw-normal py-5">{parse(stat.text)}</h2>
                    </div>
                    <div className="container">
                        {stat.img ? <img src={stat.img} className="mx-auto d-flex my-5 w-100" alt="" /> : <h4 className="text-center w-100 my-5">{t('common:no_content')}</h4>}

                        <div className="btn-container d-flex justify-content-start py-3">
                            <div className="more-info-btn py-2 px-2" onClick={() => history.goBack()}>
                                <img
                                    className="me-1"
                                    src={arrow_right}
                                    style={{ transform: "rotate(-180deg)" }}
                                    alt=""
                                />
                                <small className="me-1">{t('common:go_back')}</small>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default MenzilSechimiStatPage 
